export const generateMissingDocumentMessage = (missingDocuments) => {
  if (missingDocuments.length === 0) return false;
  missingDocuments[0] = missingDocuments[0].charAt(0).toUpperCase() + missingDocuments[0].slice(1);

  if (missingDocuments.length === 1) {
    return (
      <>
        <span className="invalid-key">{missingDocuments[0]}</span> document is not attached.
        <br />
        <span className="action">Attach the missing document and try again.</span>
      </>
    );
  }

  if (missingDocuments.length === 2) {
    return (
      <>
        <span className="invalid-key">
          {missingDocuments[0]} and {missingDocuments[1]}
        </span>{" "}
        documents are not attached.
        <br />
        <span className="action">Attach the missing documents and try again.</span>
      </>
    );
  }

  if (missingDocuments.length === 3) {
    return (
      <>
        <span className="invalid-key">
          {missingDocuments[0]}, {missingDocuments[1]} and {missingDocuments[2]}
        </span>{" "}
        documents are not attached.
        <br />
        <span className="action">Attach the missing documents and try again.</span>
      </>
    );
  }
};
