import React, { useEffect, useState } from "react";
import "./homeLayout.css";

import HomeNavbar from "./Subcomponents/HomeNavbar/HomeNavbar";

const HomeLayout = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const data = [
    {
      image: "/HomeDisplay/Shopping-Invoices.png",
      heading: "SHOPPING INVOICES",
      text: "Never worry about losing the track of your hard earned money. Quickly check during which shopping spree did you expended your money on.",
    },
    {
      image: "/HomeDisplay/Credit-Score.png",
      heading: "CREDIT SCORE",
      text: "Keep your profile's credit score high to be accepted for credits at any shops in case you forgot to carry your cash.",
    },
    {
      image: "/HomeDisplay/Business-Performance.png",
      heading: "BUSINESS PERFORMANCES",
      text: "Get your business data on the go. You never have to reach for calculator again to get your daily, monthly or yearly business performance insights.",
    },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [data.length]);

  return (
    <div className="home-layout-envelope">
      <HomeNavbar />
      <div className="site-banner">
        <div className="site-title-slogan">
          <h1>invoicesXpress</h1>
          <h4>
            Pocket just for all of your invoices, just so you never lose them...
          </h4>
        </div>
      </div>

      {data.map((item, index) => (
        <div
          className={`site-info-images-envelope ${
            index % 2 !== 0 ? "reverse" : ""
          } ${index === currentIndex ? "active" : ""}`}
          key={index}
        >
          <div className="info-image">
            <img src={item.image} alt="shopping-bags" />
          </div>
          <div className="site-info">
            <h3>{item.heading}</h3>
            <p>{item.text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomeLayout;
