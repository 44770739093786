import React from "react";
import "./contentPanel.css";

import { Routes, Route } from "react-router-dom";
import { sidebarMenus } from "../../../../dataProvider/SidemenuProvider";

const ContentPanel = () => {
  return (
    <div className="new-content-panel-envelope">
      <Routes>
        {sidebarMenus.map((menu, index) => (
          <Route key={index} path={menu.path} element={menu.element} />
        ))}
      </Routes>
    </div>
  );
};

export default ContentPanel;
