import React from "react";
import "./memberHeader.css";

import { IconCircleCaretLeftFilled } from "@tabler/icons-react";
import { IconCircleCaretRightFilled } from "@tabler/icons-react";
import { IconBellFilled } from "@tabler/icons-react"; // later get buzz icon as well: IconBellRingingFilled
import { IconSwitchVertical } from "@tabler/icons-react";
import { IconZoom } from "@tabler/icons-react";

const MemberHeader = ({ sidebarState }) => {
  const { sidebarCollapsed, setSidebarCollapsed } = sidebarState;
  return (
    <div className="member-header-envelope">
      {/* Site Info & Sidebar State Controller */}
      <div
        className={`logo-sidebar-controller ${
          sidebarCollapsed ? "collapsed" : ""
        }`}
      >
        <div className="site-logo-and-name">
          <div className="member-header-site-logo">
            <img src="logo192.png" alt="site-logo" />
          </div>
          <div className={`${sidebarCollapsed ? "hidden" : "site-name"}`}>
            <h3>invoices</h3>
            <h2>Xpress</h2>
          </div>
        </div>
        {sidebarCollapsed ? (
          <IconCircleCaretRightFilled
            size={30}
            className="sidebar-controller-icon"
            onClick={() => setSidebarCollapsed((prevState) => !prevState)}
          />
        ) : (
          <IconCircleCaretLeftFilled
            size={30}
            className="sidebar-controller-icon"
            onClick={() => setSidebarCollapsed((prevState) => !prevState)}
          />
        )}
      </div>

      {/* Search Facility */}
      <div className="global-search">
        <select className="search-selector">
          <option>Search</option>
          <option>Purchase Invoices</option>
        </select>
        <input className="search-input" placeholder="search for ...." />
        <IconZoom />
      </div>

      {/* User Profile */}
      <div className="profile-information">
        <IconBellFilled />
        <div className="profile-identity">
          <div className="profile-pic">
            <img src="logo192.png" alt="profile" />
          </div>
          <div className="name-role">
            <h3>Bibek</h3>
            <h4>Role</h4>
          </div>
        </div>
        <IconSwitchVertical />
      </div>
    </div>
  );
};

export default MemberHeader;
