import { axiosProtectedInstance } from "../axios/axios";

const usePrivateAPICall = () => {
  const executePrivateAPICall = async ({ URL, method = "get", parameters = null, signal = null }) => {
    const config = {
      method,
      url: URL,
      signal,
    };

    if (method.toLowerCase() === "get") {
      config.params = parameters;
    } else {
      config.data = parameters === null || parameters === undefined ? {} : parameters;
    }

    try {
      const response = await axiosProtectedInstance(config);

      // if (method.toLowerCase() === "delete") return { success: true, data: response };

      return { success: true, data: response?.data };
    } catch (error) {
      let errorMessage;
      if (error?.response) console.log("Error with Response", error);
      else console.log("Error with no response", error);

      if (error?.response) {
        switch (error?.response?.status) {
          case 400:
            errorMessage = error?.response?.data?.message;
            break;
          case 401:
            errorMessage = error?.response?.data?.message;
            break;
          case 403:
            errorMessage = error?.response?.data?.message;
            break;
          case 404:
            errorMessage = `The endpoint is not configured yet.`;
            break;
          case 409:
            errorMessage = error?.response?.data?.message;
            break;
          case 500:
            errorMessage = error?.response?.data?.message;
            break;

          default:
            errorMessage = `Something went wrong...`;
        }
      }
      return { success: false, errorMessage: errorMessage };
    }
  };

  return { executePrivateAPICall };
};

export default usePrivateAPICall;
