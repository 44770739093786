const keysNamingAdjuster = {
  firstname: "first name",
  middlename: "middle name",
  lastname: "last name",
  gender: "gender",
  dateOfBirth: "date of birth",
  contact: "contact",
  citizenshipNo: "citizenship no.",
};

export const generateInvalidKeysMessageForObject = (object) => {
  const invalidKeys = Object.entries(object)
    .filter(([key, value]) => value.trim() === "" || value === null || value === undefined)
    .map(([key]) => keysNamingAdjuster[key] || key);

  if (invalidKeys.length === 0) return false;

  invalidKeys[0] = invalidKeys[0].charAt(0).toUpperCase() + invalidKeys[0].slice(1);

  if (invalidKeys.length === 1) {
    return (
      <>
        <span className="invalid-key">{invalidKeys[0]}</span> value is missing.
        <br />
        <span className="action">Provide the value and try again.</span>
      </>
    );
  }
  if (invalidKeys.length === 2) {
    return (
      <>
        <span className="invalid-key">{invalidKeys[0]}</span> and <span className="invalid-key">{invalidKeys[1]}</span>{" "}
        values are missing.
        <br />
        <span className="action">Provide missing values and try again.</span>
      </>
    );
  }

  if (invalidKeys.length > 2) {
    const lastInvalidKey = invalidKeys.pop();
    const stringForRemainingInvalidKeys = invalidKeys.join(", ");

    return (
      <>
        <span className="invalid-key">{stringForRemainingInvalidKeys}</span> and{" "}
        <span className="invalid-key">{lastInvalidKey}</span> values are missing.
        <br />
        <span className="action">Provide missing values and try again.</span>
      </>
    );
  }
};
