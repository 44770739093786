import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ResponseDialog from "./components/ResponseDialog/ResponseDialog";

import PersistProtectedRoutes from "./Layouts/PersistProtectedRoutes";
import OTPVerifier from "./Layouts/HomeLayout/Subcomponents/HomeNavbar/Subcomponents/OTPVerifier/OTPVerifier";
import HomeLayout from "./Layouts/HomeLayout/HomeLayout";
import MemberLayout from "./Layouts/MemberLayout/MemberLayout";

function App() {
  return (
    <div className="app-container">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeLayout />} />
          <Route path="/otp-verification" element={<OTPVerifier />} />

          {/* Protected Routes */}
          <Route element={<PersistProtectedRoutes />}>
            <Route path="/*" element={<MemberLayout />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ResponseDialog />
    </div>
  );
}

export default App;
