import React from "react";
import "./sidebar.css";

import { NavLink } from "react-router-dom";

import { sidebarMenus } from "../../../../dataProvider/SidemenuProvider";

const Sidebar = ({ sidebarCollapsed }) => {
  return (
    <div
      className={`new-sidebar-envelope ${sidebarCollapsed ? "collapsed" : ""}`}
    >
      <div className="new-sidebar-menus-envelope">
        {sidebarMenus.map((menu, index) => (
          <NavLink
            key={index}
            to={menu.path}
            className={({ isActive }) =>
              `new-menu-item ${isActive ? "active" : ""} ${
                index === sidebarMenus.length - 1 ? "last-menu" : ""
              }`
            }
          >
            <li className="new-menu-icon">{menu.icon}</li>
            <li className="new-menu-title">
              {sidebarCollapsed ? "" : menu.title}
            </li>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
