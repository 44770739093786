import React, { useState, useRef } from "react";
import "./fileUploader.css";

const FileUploader = ({ onFileSelect }) => {
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  const fileInputRef = useRef(null);

  const handleFileUpload = (event) => {
    if (event.target.files) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      setFilePreview(URL.createObjectURL(selectedFile));

      // Send the selected file to the parent via the callback
      if (onFileSelect) {
        onFileSelect(selectedFile);
      }
    }
  };

  return (
    <div className="file-uploader">
      {/* Hidden file input with unique ref */}
      <input type="file" onChange={handleFileUpload} style={{ display: "none" }} ref={fileInputRef} />

      {/* Div that triggers the file input on click */}
      <div className="file-preview" onClick={() => fileInputRef.current.click()}>
        {file ? <img src={filePreview} alt="uploaded-images" /> : <h4>Click to select an image</h4>}
      </div>

      {file && <p>{file.name}</p>}
    </div>
  );
};

export default FileUploader;
