import React, { useState } from "react";
import "./memberLayout.css";

import MemberHeader from "./Subcomponents/MemberHeader/MemberHeader";
import Sidebar from "./Subcomponents/Sidebar/Sidebar";
import ContentPanel from "./Subcomponents/ContentPanel/ContentPanel";

const MemberLayout = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const toggleSidebarState = () => {
    setSidebarCollapsed((prevState) => !prevState);
  };
  return (
    <div className="member-layout-envelope">
      <MemberHeader sidebarState={{ sidebarCollapsed, setSidebarCollapsed }} />
      <div className="sidebar-content-envelope">
        <Sidebar sidebarCollapsed={sidebarCollapsed} />
        <ContentPanel />
      </div>
    </div>
  );
};

export default MemberLayout;
