import React from "react";
import "./responseDialog.css";
import { useResponse } from "../../context/ResponseContext";

const ResponseDialog = () => {
  const { responseMessage, setResponseMessage } = useResponse();

  if (!responseMessage) return null;

  return (
    <div className="response-overlay" onClick={() => setResponseMessage(null)}>
      <div className="response-envelope" onClick={(e) => e.stopPropagation()}>
        <h3 className="response-title">{responseMessage.title}</h3>
        <div className="response-content">
          <p className="response-message">{responseMessage.message}</p>
          <button
            className="response-btn"
            onClick={() => setResponseMessage(null)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResponseDialog;
