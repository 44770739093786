const AdministrativeDivision = [
  {
    Province: "Koshi",
    "Province Capital": "Biratnagar",
    Districts: [
      {
        District: "Bhojpur",
        Headquarter: "Bhojpur",
        Municipalities: [
          {
            Municipality: "Arun Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Aamchowk Rural Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Hatuwagadhi Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Pauwa Dungma Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Temkemaiyung Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Salpa Silichho Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Ramprasad Rai Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Shadananda Municipality",
            Wards: 14,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Bhojpur Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
        ],
      },
      {
        District: "Dhankuta",
        Headquarter: "Dhankuta",
        Municipalities: [
          {
            Municipality: "Chaubise Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Shahidbhumi Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Sangurigadhi Rural Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Chhathar Jorpati Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Pakhribas Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Mahalaxmi Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Dhankuta Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
        ],
      },
      {
        District: "Ilam",
        Headquarter: "Ilam",
        Municipalities: [
          {
            Municipality: "Rong Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Mangsebung Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Chulachuli Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Sandakpur Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Fakphokthum Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Maijogmai Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Illam Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Mai Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Deumai Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Suryodaya Municipality",
            Wards: 14,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
        ],
      },
      {
        District: "Jhapa",
        Headquarter: "Bhadrapur",
        Municipalities: [
          {
            Municipality: "Kamal Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Jhapa Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Kachankawal Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Gauriganj Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Barhadashi Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Haldibari Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Buddhashanti Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Shivasatakshi Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Bhadrapur Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Kankai Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Birtamod Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Mechinagar Municipality",
            Wards: 15,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15"],
          },
          {
            Municipality: "Damak Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Arjundhara Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Gauradhaha Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
        ],
      },
    ],
  },

  {
    Province: "Madhesh",
    "Province Capital": "Janakpur",
    Districts: [
      {
        District: "Parsa",
        Headquarter: "Birgunj",
        Municipalities: [
          {
            Municipality: "Thori Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Dhobini Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Chhipaharmai Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Jirabhawani Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Jagarnathpur Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Kalikamai Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Bindabasini Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Pakaha Mainpur Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Sakhuwa Prasauni Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Paterwa Sugauli Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Birgunj Metropolitan City",
            Wards: 32,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
              "25",
              "26",
              "27",
              "28",
              "29",
              "30",
              "31",
              "32",
            ],
          },
          {
            Municipality: "Bahudaramai Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Pokhariya Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Parsagadhi Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
        ],
      },
      {
        District: "Bara",
        Headquarter: "Kalaiya",
        Municipalities: [
          {
            Municipality: "Pheta Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Devtal Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Prasauni Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Suwarna Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Baragadhi Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Karaiyamai Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Parwanipur Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Bishrampur Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Adarsha Kotwal Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Jitpur Simara Sub-Metropolitan City",
            Wards: 24,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
            ],
          },
          {
            Municipality: "Kalaiya Sub-Metropolitan City",
            Wards: 27,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
              "25",
              "26",
              "27",
            ],
          },
          {
            Municipality: "Pacharauta Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Nijgadh Municipality",
            Wards: 13,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
          {
            Municipality: "Simraungadh Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Mahagadhimai Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Kolhabi Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
        ],
      },
      {
        District: "Rautahat",
        Headquarter: "Gaur",
        Municipalities: [
          {
            Municipality: "Yamunamai Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Durga Bhagwati Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Katahariya Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Maulapur Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Madhav Narayan Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Gaur Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Gujara Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Garuda Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Ishanath Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Chandrapur Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Dewahhi Gonahi Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Brindaban Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Rajpur Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Rajdevi Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Gadhimai Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Phatuwa Bijayapur Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Boudhimai Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Paroha Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
        ],
      },
      {
        District: "Sarlahi",
        Headquarter: "Malangwa",
        Municipalities: [
          {
            Municipality: "Dhankaul Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Parsa Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Bishnu Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Ramnagar Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Kaudena Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Basbariya Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Chandranagar Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Chakraghatta Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Bramhapuri Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Barahathawa Municipality",
            Wards: 18,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
            ],
          },
          {
            Municipality: "Haripur Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Ishworpur Municipality",
            Wards: 15,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15"],
          },
          {
            Municipality: "Lalbandi Municipality",
            Wards: 17,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
            ],
          },
          {
            Municipality: "Malangwa Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Kabilasi Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Bagmati Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Hariwan Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Balara Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Haripurwa Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Godaita Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
        ],
      },
      {
        District: "Dhanusa",
        Headquarter: "Janakpur",
        Municipalities: [
          {
            Municipality: "Aaurahi Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Dhanauji Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Bateshwor Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Janak Nandani Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Lakshminiya Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Mukhiyapatti Musarmiya Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Mithila Bihari Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Kamala Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Nagarain Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Ganeshman Charnath Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Mithila Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Dhanusadham Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Bideha Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Sabaila Municipality",
            Wards: 13,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
          {
            Municipality: "Hansapur Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Janakpurdham Sub-Metropolitan City",
            Wards: 25,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
              "25",
            ],
          },
          {
            Municipality: "Sahidnagar Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Chhireshwornath Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
        ],
      },
      {
        District: "Siraha",
        Headquarter: "Siraha",
        Municipalities: [
          {
            Municipality: "Aurahi Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Naraha Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Arnama Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Bhagawanpur Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Nawarajpur Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Bishnupur Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Bariyarpatti Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Laxmipur Patari Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Sakhuwa Nankarkatti Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Mirchaiya Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Lahan Municipality",
            Wards: 24,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
            ],
          },
          {
            Municipality: "Siraha Municipality",
            Wards: 22,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
            ],
          },
          {
            Municipality: "Dhangadhimai Municipality",
            Wards: 14,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Kalyanpur Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Karjanha Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Golbazar Municipality",
            Wards: 13,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
          {
            Municipality: "Sukhipur Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
        ],
      },
      {
        District: "Mahottari",
        Headquarter: "Jaleshwar",
        Municipalities: [
          {
            Municipality: "Pipra Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Sonama Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Samsi Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Ekadhara Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Mahottari Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Gaushala Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Ram Gopalpur Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Aurahi Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Bardibas Municipality",
            Wards: 14,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Bhangaha Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Jaleswor Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Balwa Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Manra Siswa Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Matihani Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Loharpatti Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
        ],
      },
      {
        District: "Saptari",
        Headquarter: "Rajbiraj",
        Municipalities: [
          {
            Municipality: "Rajgadh Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Rupani Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Tirahut Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Mahadeva Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Bishnupur Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Chhinnamasta Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Balan Bihul Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Tilathi Koiladi Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Agnisair Krishna Sabaran Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Hanumannagar Kankalini Municipality",
            Wards: 14,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Kanchanrup Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Rajbiraj Municipality",
            Wards: 16,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16"],
          },
          {
            Municipality: "Khadak Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Dakneshwori Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Saptakoshi Rural Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Surunga Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Shambhunath Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Bode Barsain Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
        ],
      },
    ],
  },
  {
    Province: "Bagmati",
    "Province Capital": "Hetauda",
    Districts: [
      {
        District: "Sindhuli",
        Headquarter: "Kamalamai",
        Municipalities: [
          {
            Municipality: "Marin Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Phikkal Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Tinpatan Rural Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Sunkoshi Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Golanjor Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Ghanglekh Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Hariharpurgadhi Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Dudhouli Municipality",
            Wards: 14,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Kamalamai Municipality",
            Wards: 14,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
        ],
      },
      {
        District: "Ramechhap",
        Headquarter: "Manthali",
        Municipalities: [
          {
            Municipality: "Sunapati Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Doramba Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Umakunda Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Khadadevi Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Gokulganga Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Likhu Tamakoshi Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Manthali Municipality",
            Wards: 14,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Ramechhap Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
        ],
      },
      {
        District: "Dolakha",
        Headquarter: "Bhimeshwar",
        Municipalities: [
          {
            Municipality: "Bigu Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Sailung Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Melung Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Baiteshwor Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Tamakoshi Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Gaurishankar Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Kalinchok Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Jiri Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Bhimeshwor Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
        ],
      },
      {
        District: "Bhaktapur",
        Headquarter: "Bhaktapur",
        Municipalities: [
          {
            Municipality: "Changunarayan Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Suryabinayak Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Bhaktapur Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Madhyapur Thimi Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
        ],
      },
      {
        District: "Dhading",
        Headquarter: "Nilkantha",
        Municipalities: [
          {
            Municipality: "Gajuri Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Galchi Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Thakre Rural Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Siddhalek Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Khaniyabash Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Jwalamukhi Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Gangajamuna Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Rubi Valley Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Tripura Sundari Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Netrawati Dabjong Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Benighat Rorang Rural Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Nilakantha Municipality",
            Wards: 14,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Dhunibesi Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
        ],
      },
      {
        District: "Kathmandu",
        Headquarter: "Kathmandu",
        Municipalities: [
          {
            Municipality: "Kirtipur Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Shankharapur Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Nagarjun Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Kageshwori Manahora Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Dakshinkali Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Budhanilakantha Municipality",
            Wards: 13,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
          {
            Municipality: "Tarakeshwor Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Kathmandu Metropolitan City",
            Wards: 32,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
              "25",
              "26",
              "27",
              "28",
              "29",
              "30",
              "31",
              "32",
            ],
          },
          {
            Municipality: "Tokha Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Chandragiri Municipality",
            Wards: 15,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15"],
          },
          {
            Municipality: "Gokarneshwor Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
        ],
      },
      {
        District: "Kavrepalanchok",
        Headquarter: "Dhulikhel",
        Municipalities: [
          {
            Municipality: "Roshi Rural Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Temal Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Bhumlu Rural Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Mahabharat Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Bethanchowk Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Khanikhola Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Chauri Deurali Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Banepa Municipality",
            Wards: 14,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Mandan Deupur Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Dhulikhel Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Panauti Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Namobuddha Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Panchkhal Municipality",
            Wards: 13,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
        ],
      },
      {
        District: "Lalitpur",
        Headquarter: "Lalitpur",
        Municipalities: [
          {
            Municipality: "Bagmati Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Mahankal Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Konjyosom Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Lalitpur Metropolitan City",
            Wards: 29,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
              "25",
              "26",
              "27",
              "28",
              "29",
            ],
          },
          {
            Municipality: "Mahalaxmi Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Godawari Municipality",
            Wards: 14,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
        ],
      },
      {
        District: "Nuwakot",
        Headquarter: "Bidur",
        Municipalities: [
          {
            Municipality: "Kakani Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Tadi Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Likhu Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Myagang Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Shivapuri Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Kispang Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Suryagadhi Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Tarkeshwor Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Panchakanya Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Dupcheshwar Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Belkotgadhi Municipality",
            Wards: 13,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
          {
            Municipality: "Bidur Municipality",
            Wards: 13,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
        ],
      },
      {
        District: "Rasuwa",
        Headquarter: "Dhunche",
        Municipalities: [
          {
            Municipality: "Kalika Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Naukunda Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Uttargaya Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Gosaikunda Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Amachodingmo Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
        ],
      },
      {
        District: "Sindhupalchok",
        Headquarter: "Chautara",
        Municipalities: [
          {
            Municipality: "Jugal Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Balefi Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Sunkoshi Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Helambu Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Bhotekoshi Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Lisangkhu Pakhar Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Indrawati Rural Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Tripurasundari Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Panchpokhari Thangpal Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Chautara Sangachok Gadhi Municipality",
            Wards: 14,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Barhabise Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Melamchi Municipality",
            Wards: 13,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
        ],
      },
      {
        District: "Chitwan",
        Headquarter: "Bharatpur",
        Municipalities: [
          {
            Municipality: "Ichchhya Kamana Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Bharatpur Metropolitian City",
            Wards: 29,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
              "25",
              "26",
              "27",
              "28",
              "29",
            ],
          },
          {
            Municipality: "Kalika Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Khairahani Municipality",
            Wards: 13,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
          {
            Municipality: "Madi Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Rapti Municipality",
            Wards: 13,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
          {
            Municipality: "Ratnanagar Municipality",
            Wards: 16,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16"],
          },
        ],
      },
      {
        District: "Makwanpur",
        Headquarter: "Hetauda",
        Municipalities: [
          {
            Municipality: "Bakaiya Rural Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Kailash Rural Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Manahari Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Bhimphedi Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Bagmati Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Raksirang Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Makawanpurgadhi Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Indrasarowar Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Hetauda Sub-Metropolitian City",
            Wards: 19,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
            ],
          },
          {
            Municipality: "Thaha Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
        ],
      },
    ],
  },
  {
    Province: "Gandaki",
    "Province Capital": "Pokhara",
    Districts: [
      {
        District: "Baglung",
        Headquarter: "Baglung",
        Municipalities: [
          {
            Municipality: "Bareng Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Badigad Rural Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Nisikhola Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Kathekhola Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Tara Khola Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Taman Khola Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Jaimuni Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Baglung Municipality",
            Wards: 14,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Galkot Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Dhorpatan Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
        ],
      },
      {
        District: "Gorkha",
        Headquarter: "Gorkha",
        Municipalities: [
          {
            Municipality: "Gandaki Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Dharche Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Ajirkot Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Aarughat Rural Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Sahid Lakhan Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Siranchok Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Bhimsenthapa Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Chumanubri Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Barpak Sulikot Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Palungtar Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Gorkha Municipality",
            Wards: 14,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
        ],
      },
      {
        District: "Kaski",
        Headquarter: "Pokhara",
        Municipalities: [
          {
            Municipality: "Rupa Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Madi Rural Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Annapurna Rural Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Machhapuchchhre Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Pokhara Metropolitan City",
            Wards: 33,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
              "25",
              "26",
              "27",
              "28",
              "29",
              "30",
              "31",
              "32",
              "33",
            ],
          },
        ],
      },
      {
        District: "Lamjung",
        Headquarter: "Besisahar",
        Municipalities: [
          {
            Municipality: "Dordi Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Dudhpokhari Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Marsyangdi Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Kwhola Sothar Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Sundarbazar Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Besishahar Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Rainas Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Madhya Nepal Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
        ],
      },
      {
        District: "Manang",
        Headquarter: "Chame",
        Municipalities: [
          {
            Municipality: "Chame Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Narshon Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Narpa Bhumi Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Manang Ingshyang Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
        ],
      },
      {
        District: "Mustang",
        Headquarter: "Jomsom",
        Municipalities: [
          {
            Municipality: "Thasang Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Gharapjhong Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Lomanthang Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Lo-Ghekar Damodarkunda Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Varagung Muktikhsetra Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
        ],
      },
      {
        District: "Myagdi",
        Headquarter: "Beni",
        Municipalities: [
          {
            Municipality: "Mangala Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Malika Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Raghuganga Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Dhaulagiri Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Annapurna Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Beni Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
        ],
      },
      {
        District: "East Nawalparasi",
        Headquarter: "Kawasoti",
        Municipalities: [
          {
            Municipality: "Baudikali Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Bulingtar Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Hupsekot Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Binayi Tribeni Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Madhyabindu Municipality",
            Wards: 15,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15"],
          },
          {
            Municipality: "Devchuli Municipality",
            Wards: 17,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
            ],
          },
          {
            Municipality: "Gaidakot Municipality",
            Wards: 18,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
            ],
          },
          {
            Municipality: "Kawasoti Municipality",
            Wards: 17,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
            ],
          },
        ],
      },
      {
        District: "Parbat",
        Headquarter: "Kusma",
        Municipalities: [
          {
            Municipality: "Modi Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Paiyu Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Jaljala Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Bihadi Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Mahashila Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Kushma Municipality",
            Wards: 14,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Phalebas Municipality",
            Wards: 11,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
        ],
      },
      {
        District: "Syangja",
        Headquarter: "Putalibazar",
        Municipalities: [
          {
            Municipality: "Harinas Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Biruwa Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Aandhikhola Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Phedikhola Rural Municipality",
            Wards: 5,
            WardsNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Kaligandaki Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Arjun Chaupari Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Putalibazar Municipality",
            Wards: 14,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Bhirkot Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Galyang Municipality",
            Wards: 11,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Chapakot Municipality",
            Wards: 10,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Waling Municipality",
            Wards: 14,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
        ],
      },
      {
        District: "Tanahun",
        Headquarter: "Damauli",
        Municipalities: [
          {
            Municipality: "Ghiring Rural Municipality",
            Wards: 5,
            WardsNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Devghat Rural Municipality",
            Wards: 5,
            WardsNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Rhishing Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Myagde Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Bandipur Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Anbukhaireni Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Byas Municipality",
            Wards: 14,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Shuklagandaki Municipality",
            Wards: 12,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Bhimad Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Bhanu Municipality",
            Wards: 13,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
        ],
      },
    ],
  },
  {
    Province: "Lumbini",
    "Province Capital": "Butwal",
    Districts: [
      {
        District: "Kapilbastu",
        Headquarter: "Taulihawa",
        Municipalities: [
          {
            Municipality: "Yashodhara Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Bijayanagar Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Mayadevi Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Suddhodhan Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Shivaraj Municipality",
            Wards: 11,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Kapilbastu Municipality",
            Wards: 12,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Buddhabhumi Municipality",
            Wards: 10,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Maharajgunj Municipality",
            Wards: 11,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Banganga Municipality",
            Wards: 11,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Krishnanagar Municipality",
            Wards: 12,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
        ],
      },
      {
        District: "West Nawalparasi",
        Headquarter: "Ramgram",
        Municipalities: [
          {
            Municipality: "Sarawal Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Susta Rural Municipality",
            Wards: 5,
            WardsNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Pratappur Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Palhi Nandan Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Bardaghat Municipality",
            Wards: 16,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16"],
          },
          {
            Municipality: "Sunwal Municipality",
            Wards: 13,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
          {
            Municipality: "Ramgram Municipality",
            Wards: 18,
            WardsNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
            ],
          },
        ],
      },
      {
        District: "Rupandehi",
        Headquarter: "Siddharthanagar",
        Municipalities: [
          {
            Municipality: "Kanchan Rural Municipality",
            Wards: 5,
            WardsNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Siyari Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Rohini Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Gaidahawa Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Omsatiya Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Sudhdhodhan Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Mayadevi Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Marchawari Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Kotahimai Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Sammarimai Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Butwal Sub-Metropolitian City",
            Wards: 19,
            WardsNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
            ],
          },
          {
            Municipality: "Lumbini Sanskritik Municipality",
            Wards: 13,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
          {
            Municipality: "Devdaha Municipality",
            Wards: 12,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Sainamaina Municipality",
            Wards: 11,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Siddharthanagar Municipality",
            Wards: 13,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
          {
            Municipality: "Tillotama Municipality",
            Wards: 17,
            WardsNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
            ],
          },
        ],
      },
      {
        District: "Arghakhanchi",
        Headquarter: "Sandhikharka",
        Municipalities: [
          {
            Municipality: "Panini Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Chhatradev Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Malarani Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Bhumikasthan Municipality",
            Wards: 10,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Sitganga Municipality",
            Wards: 14,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Sandhikharka Municipality",
            Wards: 12,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
        ],
      },
      {
        District: "Gulmi",
        Headquarter: "Tamghas",
        Municipalities: [
          {
            Municipality: "Ruruchhetra Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Isma Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Madane Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Malika Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Chatrakot Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Dhurkot Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Satyawati Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Chandrakot Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Kaligandaki Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Gulmi Durbar Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Resunga Municipality",
            Wards: 14,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Musikot Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
        ],
      },
      {
        District: "Palpa",
        Headquarter: "Tansen",
        Municipalities: [
          {
            Municipality: "Rambha Rural Municipality",
            Wards: 5,
            WardsNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Tinau Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Nisdi Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Mathagadhi Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Ribdikot Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Purbakhola Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Bagnaskali Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Rainadevi Chhahara Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Tansen Municipality",
            Wards: 14,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Rampur Municipality",
            Wards: 10,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
        ],
      },
      {
        District: "Dang",
        Headquarter: "Ghorahi",
        Municipalities: [
          {
            Municipality: "Babai Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Gadhawa Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Rapti Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Rajpur Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Dangisharan Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Shantinagar Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Banglachuli Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Tulsipur Sub-Metropolitian City",
            Wards: 19,
            WardsNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
            ],
          },
          {
            Municipality: "Ghorahi Sub-Metropolitian City",
            Wards: 19,
            WardsNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
            ],
          },
          {
            Municipality: "Lamahi Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
        ],
      },
      {
        District: "Pyuthan",
        Headquarter: "Pyuthan",
        Municipalities: [
          {
            Municipality: "Ayirabati Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Gaumukhi Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Jhimruk Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Naubahini Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Mandavi Rural Municipality",
            Wards: 5,
            WardsNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Mallarani Rural Municipality",
            Wards: 5,
            WardsNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Sarumarani Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Pyuthan Municipality",
            Wards: 10,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Sworgadwari Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
        ],
      },
      {
        District: "Rolpa",
        Headquarter: "Liwang",
        Municipalities: [
          {
            Municipality: "Madi Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Thawang Rural Municipality",
            Wards: 5,
            WardsNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Sunchhahari Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Lungri Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Gangadev Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Tribeni Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Pariwartan Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Runtigadi Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Sunil Smriti Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Rolpa Municipality",
            Wards: 10,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
        ],
      },
      {
        District: "Eastern Rukum",
        Headquarter: "Rukumkot",
        Municipalities: [
          {
            Municipality: "Bhume Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Sisne Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Putha Uttarganga Rural Municipality",
            Wards: 14,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
        ],
      },
      {
        District: "Banke",
        Headquarter: "Nepalgunj",
        Municipalities: [
          {
            Municipality: "Khajura Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Janki Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Baijanath Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Duduwa Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Narainapur Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Rapti Sonari Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Kohalpur Municipality",
            Wards: 15,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15"],
          },
          {
            Municipality: "Nepalgunj Sub-Metropolitian City",
            Wards: 23,
            WardsNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
            ],
          },
        ],
      },
      {
        District: "Bardiya",
        Headquarter: "Gulariya",
        Municipalities: [
          {
            Municipality: "Geruwa Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Badhaiyatal Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Thakurbaba Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Bansagadhi Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Barbardiya Municipality",
            Wards: 11,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Rajapur Municipality",
            Wards: 10,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Madhuwan Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Gulariya Municipality",
            Wards: 12,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
        ],
      },
    ],
  },
  {
    Province: "Karnali",
    "Province Capital": "Birendranagar",
    Districts: [
      {
        District: "Western Rukum",
        Headquarter: "Musikot",
        Municipalities: [
          {
            Municipality: "Tribeni Rural Municipality",
            Wards: 10,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Sani Bheri Rural Municipality",
            Wards: 11,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Banphikot Rural Municipality",
            Wards: 10,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Aathbiskot Municipality",
            Wards: 14,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Chaurjahari Municipality",
            Wards: 14,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Musikot Municipality",
            Wards: 14,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
        ],
      },
      {
        District: "Salyan",
        Headquarter: "Salyan",
        Municipalities: [
          {
            Municipality: "Kumakh Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Darma Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Kapurkot Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Kalimati Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Tribeni Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Chhatreshwori Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Siddha Kumakh Rural Municipality",
            Wards: 5,
            WardsNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Sharada Municipality",
            Wards: 15,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15"],
          },
          {
            Municipality: "Bangad Kupinde Municipality",
            Wards: 12,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Bagchaur Municipality",
            Wards: 12,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
        ],
      },
      {
        District: "Dolpa",
        Headquarter: "Dunai",
        Municipalities: [
          {
            Municipality: "Kaike Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Jagadulla Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Mudkechula Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Dolpo Buddha Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Shey Phoksundo Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Chharka Tangsong Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Tripurasundari Municipality",
            Wards: 11,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Thuli Bheri Municipality",
            Wards: 11,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
        ],
      },
      {
        District: "Humla",
        Headquarter: "Simikot",
        Municipalities: [
          {
            Municipality: "Simkot Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Namkha Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Chankheli Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Tanjakot Rural Municipality",
            Wards: 5,
            WardsNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Sarkegad Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Adanchuli Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Kharpunath Rural Municipality",
            Wards: 5,
            WardsNo: ["01", "02", "03", "04", "05"],
          },
        ],
      },
      {
        District: "Jumla",
        Headquarter: "Chandannath",
        Municipalities: [
          {
            Municipality: "Hima Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Tila Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Sinja Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Guthichaur Rural Municipality",
            Wards: 5,
            WardsNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Tatopani Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Patrasi Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Kanaka Sundari Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Chandannath Municipality",
            Wards: 10,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
        ],
      },
      {
        District: "Kalikot",
        Headquarter: "Manma",
        Municipalities: [
          {
            Municipality: "Mahawai Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Palata Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Naraharinath Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Pachal Jharana Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Subha Kalika Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Sanni Tribeni Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Khandachakra Municipality",
            Wards: 11,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Raskot Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Tilagufa Municipality",
            Wards: 11,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
        ],
      },
      {
        District: "Mugu",
        Headquarter: "Gamgadhi",
        Municipalities: [
          {
            Municipality: "Soru Rural Municipality",
            Wards: 11,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Khatyad Rural Municipality",
            Wards: 11,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Mugum Karmarong Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Chhayanath Rara Municipality",
            Wards: 14,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
        ],
      },
      {
        District: "Surkhet",
        Headquarter: "Birendranagar",
        Municipalities: [
          {
            Municipality: "Chaukune Rural Municipality",
            Wards: 10,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Simta Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Chingad Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Barahatal Rural Municipality",
            Wards: 10,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Gurbhakot Municipality",
            Wards: 14,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Panchapuri Municipality",
            Wards: 11,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Bheriganga Municipality",
            Wards: 13,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
          {
            Municipality: "Lekabeshi Municipality",
            Wards: 10,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Birendranagar Municipality",
            Wards: 16,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16"],
          },
        ],
      },
      {
        District: "Dailekh",
        Headquarter: "Narayan",
        Municipalities: [
          {
            Municipality: "Bhairabi Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Mahabu Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Gurans Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Naumule Rural Municipality",
            Wards: 8,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Bhagawatimai Rural Municipality",
            Wards: 7,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Thantikandh Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Dungeshwor Rural Municipality",
            Wards: 6,
            WardsNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Aathabis Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Dullu Municipality",
            Wards: 13,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
          {
            Municipality: "Chamunda Bindrasaini Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Narayan Municipality",
            Wards: 11,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
        ],
      },
      {
        District: "Jajarkot",
        Headquarter: "Khalanga",
        Municipalities: [
          {
            Municipality: "Kuse Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Shivalaya Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Barekot Rural Municipality",
            Wards: 9,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Junichande Rural Municipality",
            Wards: 11,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Nalagad Municipality",
            Wards: 13,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
          {
            Municipality: "Bheri Municipality",
            Wards: 13,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
          {
            Municipality: "Chhedagad Municipality",
            Wards: 13,
            WardsNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
          },
        ],
      },
    ],
  },
  {
    Province: "Sudurpaschim",
    "Province Capital": "Godawari",
    Districts: [
      {
        District: "Kailali",
        Headquarter: "Dhangadi",
        Municipalities: [
          {
            Municipality: "Chure Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Janaki Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Kailari Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Joshipur Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Mohanyal Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Bardagoriya Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Tikapur Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Ghodaghodi Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Bhajani Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Dhangadhi Sub-Metropolitian City",
            Wards: 19,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
            ],
          },
          {
            Municipality: "Gauriganga Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Godawari Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
          {
            Municipality: "Lamki Chuha Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
        ],
      },
      {
        District: "Achham",
        Headquarter: "Mangalsen",
        Municipalities: [
          {
            Municipality: "Dhakari Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Mellekh Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Chaurpati Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Ramaroshan Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Turmakhad Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Bannigadhi Jayagadh Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Sanphebagar Municipality",
            Wards: 14,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Mangalsen Municipality",
            Wards: 14,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"],
          },
          {
            Municipality: "Kamalbazar Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Panchadewal Binayak Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
        ],
      },
      {
        District: "Doti",
        Headquarter: "Dipayal",
        Municipalities: [
          {
            Municipality: "Sayal Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Adharsha Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Jorayal Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Badikedar Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Purbichauki Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "K I Singh Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Bogtan Phudsil Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Dipayal Silgadi Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Shikhar Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
        ],
      },
      {
        District: "Bajhang",
        Headquarter: "Jayaprithivi",
        Municipalities: [
          {
            Municipality: "Masta Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Thalara Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Talkot Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Surma Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Saipaal Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Durgathali Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Bithadchir Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Kedarsen Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Khaptad Chhanna Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Chabis Pathivera Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Jaya Prithivi Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Bungal Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
        ],
      },
      {
        District: "Bajura",
        Headquarter: "Martadi",
        Municipalities: [
          {
            Municipality: "Gaumul Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Himali Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Jagannath Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Khaptad Chhededaha Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Swami Kartik Khaapar Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Badimalika Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Tribeni Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Budhiganga Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Budhinanda Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
        ],
      },
      {
        District: "Kanchanpur",
        Headquarter: "Bheemdatta",
        Municipalities: [
          {
            Municipality: "Beldandi Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Laljhadi Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Punarbas Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Krishnapur Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Mahakali Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Bedkot Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Belauri Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Bhimdatta Municipality",
            Wards: 19,
            WardNo: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
            ],
          },
          {
            Municipality: "Shuklaphanta Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
        ],
      },
      {
        District: "Dadeldhura",
        Headquarter: "Amargadhi",
        Municipalities: [
          {
            Municipality: "Alital Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Ajaymeru Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Bhageshwar Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Nawadurga Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Ganayapdhura Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Amargadhi Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Parashuram Municipality",
            Wards: 12,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          },
        ],
      },
      {
        District: "Baitadi",
        Headquarter: "Dasharathchand",
        Municipalities: [
          {
            Municipality: "Sigas Rural Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Shivanath Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Surnaya Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Dilasaini Rural Municipality",
            Wards: 7,
            WardNo: ["01", "02", "03", "04", "05", "06", "07"],
          },
          {
            Municipality: "Pancheshwar Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Dogadakedar Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Melauli Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Dasharathchanda Municipality",
            Wards: 11,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
          },
          {
            Municipality: "Purchaudi Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
          {
            Municipality: "Patan Municipality",
            Wards: 10,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
          },
        ],
      },
      {
        District: "Darchula",
        Headquarter: "Khalanga",
        Municipalities: [
          {
            Municipality: "Lekam Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Naugad Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Byas Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Duhun Rural Municipality",
            Wards: 5,
            WardNo: ["01", "02", "03", "04", "05"],
          },
          {
            Municipality: "Marma Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Apihimal Rural Municipality",
            Wards: 6,
            WardNo: ["01", "02", "03", "04", "05", "06"],
          },
          {
            Municipality: "Malikaarjun Rural Municipality",
            Wards: 8,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08"],
          },
          {
            Municipality: "Mahakali Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
          {
            Municipality: "Shailyashikhar Municipality",
            Wards: 9,
            WardNo: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          },
        ],
      },
    ],
  },
];

export const provinces = AdministrativeDivision.map((item) => item.Province);

export const getDistrictsByProvince = (provinceName) => {
  const selectedProvince = AdministrativeDivision.find((province) => province.Province === provinceName);
  const districts = selectedProvince ? selectedProvince.Districts.map((district) => district.District) : [];
  // return districts.length > 0 ? districts : ["Select a district"];
  return districts;
};

export const getMunicipalitiesByProvinceAndDistrict = (provinceName, districtName) => {
  const selectedProvince = AdministrativeDivision.find((province) => province.Province === provinceName);
  const selectedDistrict = selectedProvince?.Districts.find((district) => district.District === districtName);
  const municipalities = selectedDistrict
    ? selectedDistrict.Municipalities.map((municipality) => municipality.Municipality)
    : [];
  // return municipalities.length > 0 ? municipalities : ["Select a municipality"];
  return municipalities;
};

export const getWardsByMunicipality = (provinceName, districtName, municipalityName) => {
  const selectedProvince = AdministrativeDivision.find((province) => province.Province === provinceName);
  const selectedDistrict = selectedProvince?.Districts.find((district) => district.District === districtName);
  const selectedMunicipality = selectedDistrict?.Municipalities.find(
    (municipality) => municipality.Municipality === municipalityName
  );
  const wards = selectedMunicipality ? selectedMunicipality.WardNo : [];
  // return wards.length > 0 ? wards : ["Select a ward"];
  return wards;
};
