import usePrivateAPICall from "../../APICallHooks/usePrivateAPICall";
import { useResponse } from "../../../context/ResponseContext";

import { SAVE_MEMBER_KYC, SAVE_MEMBER_KYC_DOCUMENTS, DELETE_MEMBER_KYC } from "../URLsDefinations/MemberURLs";

const useMemberActions = () => {
  const { executePrivateAPICall } = usePrivateAPICall();
  const { setResponseMessage } = useResponse();

  const saveMemberKYC = async (memberDetail) => {
    const controller = new AbortController();
    const { signal } = controller;

    try {
      const response = await executePrivateAPICall({
        URL: SAVE_MEMBER_KYC,
        method: "post",
        parameters: { memberDetail },
        signal,
      });

      if (response?.success) {
        return true;
      } else {
        setResponseMessage({
          status: "error",
          title: "KYC Submission Failed",
          message: response?.errorMessage,
        });
        return;
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        setResponseMessage({
          status: "error",
          title: "KYC Submission Request Failed",
          message: error.message,
        });
      }
      return;
    } finally {
      controller.abort();
    }
  };

  const saveMemberKYCDocuments = async (memberDocuments) => {
    const controller = new AbortController();
    const { signal } = controller;

    try {
      const response = await executePrivateAPICall({
        URL: SAVE_MEMBER_KYC_DOCUMENTS,
        method: "post",
        parameters: memberDocuments,
        signal,
      });

      if (response?.success) {
        setResponseMessage({
          status: "success",
          title: "KYC Submission Completed",
          message: response?.data?.message,
        });
      } else {
        setResponseMessage({
          status: "error",
          title: "KYC Documents Submission Failed",
          message: response?.errorMessage,
        });
        return;
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        setResponseMessage({
          status: "error",
          title: "KYC Documents Submission Request Failed",
          message: error.message,
        });
      }
      return;
    } finally {
      controller.abort();
    }
  };

  const deleteMemberKYCRecord = async () => {
    const controller = new AbortController();
    const { signal } = controller;

    try {
      const response = await executePrivateAPICall({ URL: DELETE_MEMBER_KYC, method: "delete", signal });
      console.log("DELETE", response);
      if (response?.success) {
        setResponseMessage({
          status: "success",
          title: `KYC Record Deletion Successful`,
          message: response?.data?.message,
        });
        return true;
      } else {
        setResponseMessage({
          status: "error",
          title: "KYC Record Deletion Failed",
          message: response?.errorMessage,
        });
        return;
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        setResponseMessage({
          status: "error",
          title: "KYC Record Delete Request Failed",
          message: error.message,
        });
      }
      return;
    } finally {
      controller.abort();
    }
  };

  return { saveMemberKYC, saveMemberKYCDocuments, deleteMemberKYCRecord };
};

export default useMemberActions;
