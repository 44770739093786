import React, { useState, useEffect } from "react";
import "./profileCards.css";

import { useAuth } from "../../../../../context/AuthContext";

import KYC from "../KYC/KYC";

const ProfileCards = () => {
  const { user } = useAuth();
  const [openKYCForm, setOpenKycForm] = useState(false);

  useEffect(() => {}, [user.kyc_status]);
  return (
    <div className="profile-cards-envelope">
      {user.kyc_status === "Pending" && (
        <div className="kyc-profile-card">
          <div className="kyc-card-title">
            <h3>MY KYC</h3>
            <h4>{user.kyc_status}</h4>
          </div>

          <div className="kyc-card-description">
            <p>Documents Needed:</p>
            <br />
            <li>-Citizenship Copy (Front and Back)</li>
            <li>-Recent passport size photo</li>
          </div>

          <button onClick={() => setOpenKycForm(true)}>Fill KYC</button>
        </div>
      )}

      {user.kyc_status === "Applied" && (
        <div className="kyc-profile-card">
          <div className="kyc-card-title">
            <h3>UPDATE KYC</h3>
            <h4>{user.kyc_status}</h4>
          </div>

          <div className="kyc-card-description">
            <p>Documents Needed:</p>
            <br />
            <li>-Citizenship Copy (Front and Back)</li>
            <li>-Recent passport size photo</li>
          </div>

          <button onClick={() => setOpenKycForm(true)}>Fill KYC</button>
        </div>
      )}

      <KYC open={openKYCForm} handleClose={() => setOpenKycForm(false)} />
      <KYC open={openKYCForm} handleClose={() => setOpenKycForm(false)} />
    </div>
  );
};

export default ProfileCards;
