import useAPICall from "../../APICallHooks/useAPICall";
import { useResponse } from "../../../context/ResponseContext";

import { VERIFY_REGISTRATION_OTP } from "../URLsDefinations/OTPURLs";

const useOTPActions = () => {
  const { executeAPICall } = useAPICall();
  const { setResponseMessage } = useResponse();

  const verifyRegistrationOTP = async (OTPDetails) => {
    const controller = new AbortController();
    const { signal } = controller;

    try {
      const response = await executeAPICall({
        URL: VERIFY_REGISTRATION_OTP,
        method: "post",
        parameters: { OTPDetails },
        signal,
      });

      if (response?.success) {
        setResponseMessage({
          status: "success",
          title: "OTP Verification Complete",
          message: response?.data?.message,
        });
        return true;
      } else {
        setResponseMessage({
          status: "error",
          title: "OTP Verification Failed",
          message: response?.errorMessage,
        });
        return false;
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        setResponseMessage({
          status: "error",
          title: "OTP Verification Request Failed",
          message: error.message,
        });
      }
      return false;
    } finally {
      controller.abort();
    }
  };

  return { verifyRegistrationOTP };
};

export default useOTPActions;
