import { IconLayoutDashboard } from "@tabler/icons-react";
import { IconUserBolt } from "@tabler/icons-react";
import { IconReceipt } from "@tabler/icons-react";
import { IconSettings2 } from "@tabler/icons-react";
import { IconLogout2 } from "@tabler/icons-react";

import { IconGridScan } from "@tabler/icons-react";

import Dashboard from "../Layouts/LayoutContent/Dashboard/Dashboard";
import Profile from "../Layouts/LayoutContent/Profile/Profile";
import Invoices from "../Layouts/LayoutContent/Invoices/Invoices";
import Settings from "../Layouts/LayoutContent/Settings/Settings";

export const sidebarMenus = [
  {
    title: "Profile",
    icon: <IconUserBolt />,
    path: "profile",
    element: <Profile />,
  },
  {
    title: "Dashboard",
    icon: <IconLayoutDashboard />,
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    title: "Invoices",
    icon: <IconReceipt />,
    path: "invoices",
    element: <Invoices />,
  },
  {
    title: "Setting",
    icon: <IconSettings2 />,
    path: "settings",
    element: <Settings />,
  },
  { title: "Logout", icon: <IconLogout2 />, path: "logout" },
];
