import React from "react";
import "./profile.css";

import ProfileCards from "./Subcomponents/ProfileCards/ProfileCards";
import KYC from "./Subcomponents/KYC/KYC";

const Profile = () => {
  return (
    <div className="profile-envelope">
      <ProfileCards />
    </div>
  );
};

export default Profile;
