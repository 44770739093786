import React, { useState, useEffect } from "react";
import "./loginSignup.css";

import { useNavigate } from "react-router-dom";

import { IconUserFilled, IconKeyFilled, IconMailFilled, IconInfoCircleFilled } from "@tabler/icons-react";

import useAccessHooks from "../../../../../../config/URLs/URLCallHooks/useAccessHooks";

const USERNAME_REGEX = /^[a-zA-Z0-9]{3,16}$/;
const EMAIL_REGEX = /^(?![_.])[A-Za-z0-9._%+-]+(?:\.[A-Za-z0-9._%+-]+)*@(?:[A-Za-z0-9-]+\.)+[A-Za-z0-9]{2,}$/;
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/;

const LoginSignup = ({ open, handleClose, form }) => {
  const { mode, setMode } = form;
  const closeLoginSignupForm = () => {
    setMode(null);
    handleClose();
  };

  // Avoid conditional hook call by ensuring the component renders at least a div

  const stopPropagation = (e) => e.stopPropagation();

  const { signup, login } = useAccessHooks();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");

  const [username, setUsername] = useState("");
  const [focusUsername, setFocusUsername] = useState(false);
  const [validUsername, setValidUsername] = useState(false);

  const [email, setEmail] = useState("");
  const [focusEmail, setFocusEmail] = useState(false);
  const [validEmail, setValidEmail] = useState(false);

  const [password, setPassword] = useState("");
  const [focusPassword, setFocusPassword] = useState(false);
  const [validPassword, setValidPassword] = useState(false);

  const clearInputsAndStates = () => {
    setUsername("");
    setFocusUsername(false);
    setValidUsername(false);
    setEmail("");
    setFocusEmail(false);
    setValidEmail(false);
    setPassword("");
    setFocusPassword(false);
    setValidPassword(false);
    setErrorMsg("");
  };

  const clearErrorMsg = () => {
    setErrorMsg("");
  };

  const isUsernameValid = (username) => {
    return USERNAME_REGEX.test(username);
  };

  const isEmailValid = (email) => {
    return EMAIL_REGEX.test(email);
  };

  const isPasswordValid = (password) => {
    return PASSWORD_REGEX.test(password);
  };

  useEffect(() => {
    setValidUsername(isUsernameValid(username));
  }, [username]);

  useEffect(() => {
    setValidEmail(isEmailValid(email));
  }, [email]);

  useEffect(() => {
    setValidPassword(isPasswordValid(password));
  }, [password]);

  /* ===================================================== HANDLE SIGNUP START ======================================================== */
  const initiateSignUp = async () => {
    if (!isUsernameValid(username)) {
      setErrorMsg(`Invalid username. Follow the instructions shown.`);
      return;
    }

    if (!isEmailValid(email)) {
      setErrorMsg(`Invalid email. Follow the instructions shown.`);
      return;
    }

    if (!isPasswordValid(password)) {
      setErrorMsg(`Invalid password. Follow the instructions shown.`);
      return;
    }

    const signupCredentials = { username, email, password };
    const response = await signup(signupCredentials);
    if (response?.success) {
      clearInputsAndStates();
      navigate("/otp-verification");
    } else {
      setErrorMsg(response?.errorMessage);
      return;
    }
  };
  /* ====================================================== HANDLE SIGNUP END ======================================================== */

  /* ====================================================== HANDLE LOGIN START ======================================================== */
  const initiateLogin = async () => {
    if (!isUsernameValid(username)) {
      setErrorMsg(`Invalid username. Follow the instructions shown.`);
      return;
    }

    if (!isPasswordValid(password)) {
      setErrorMsg(`Invalid password. Follow the instructions shown.`);
      return;
    }

    const loginCredentials = { username, password };
    const response = await login(loginCredentials);
    if (response?.success) {
      clearInputsAndStates();
      navigate("/dashboard");
    } else {
      if (response?.status === 412) {
        clearInputsAndStates();
        setMode("Sign Up");
        return;
      }
      if (response?.status === 428) {
        navigate("/otp-verification");
        return;
      }
      setErrorMsg(response?.errorMessage);
      return;
    }
  };
  /* ====================================================== HANDLE LOGIN END ========================================================== */

  if (!open) {
    return null;
  }

  return (
    <div className="login-signup-overlay" onClick={closeLoginSignupForm}>
      <div className="login-signup-envelope" onClick={stopPropagation}>
        <div className="login-signup-site-identity-envelope">
          <div className="login-signup-site-logo">
            <img src="logo192.png" alt="site" />
          </div>
          <h1>invoicesXpress</h1>
        </div>
        <h3>{mode}</h3>
        {mode === "Login" && (
          <>
            <div className="login-signup-inputs">
              <span className="access-inputs">
                <IconUserFilled className="access-icons" />
                <input
                  type="text"
                  name="username"
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                  onFocus={() => {
                    setFocusUsername(true);
                    clearErrorMsg();
                  }}
                  onBlur={() => setFocusUsername(false)}
                  autoComplete="off"
                  className={`${focusUsername && !validUsername ? "invalid-inputs" : ""}`}
                  placeholder="username"
                />
              </span>
              <span className="access-inputs">
                <IconKeyFilled className="access-icons" />
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  onFocus={() => {
                    setFocusPassword(true);
                    clearErrorMsg();
                  }}
                  onBlur={() => setFocusPassword(false)}
                  autoComplete="off"
                  className={`${focusPassword && !validPassword ? "invalid-inputs" : ""}`}
                  placeholder="password"
                />
              </span>
              <p className="forgot-password">Forgot Password?</p>
            </div>
            <div className="action-response">
              {errorMsg && <p>{errorMsg}</p>}

              <button onClick={initiateLogin}>Login</button>
            </div>

            <div className="login-signup-toggle">
              <p>Not a member yet?</p>
              <p
                onClick={() => {
                  setMode("Sign Up");
                  clearInputsAndStates();
                }}
              >
                Sign Up
              </p>
            </div>
          </>
        )}

        {mode === "Sign Up" && (
          <>
            <div className="login-signup-inputs">
              <span className="access-inputs">
                <IconUserFilled className="access-icons" />
                <input
                  type="text"
                  name="username"
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                  onFocus={() => {
                    setFocusUsername(true);
                    clearErrorMsg();
                  }}
                  onBlur={() => setFocusUsername(false)}
                  autoComplete="off"
                  className={`${focusUsername && !validUsername ? "invalid-inputs" : ""}`}
                  placeholder="username"
                />
                {focusUsername && !validUsername && (
                  <span className="access-credentials-instruction">
                    <IconInfoCircleFilled className="credentials-instruction-icon" />
                    <p className="">
                      - Must be between 3 and 16 characters long.
                      <br />
                      - Can include capital and small letters and numbers.
                      <br />- Special characters or spaces are not allowed.
                    </p>
                  </span>
                )}
              </span>
              <span className="access-inputs">
                <IconMailFilled className="access-icons" />
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  onFocus={() => {
                    setFocusEmail(true);
                    clearErrorMsg();
                  }}
                  onBlur={() => setFocusEmail(false)}
                  autoComplete="off"
                  className={`${focusEmail && !validEmail ? "invalid-inputs" : ""}`}
                  placeholder="email"
                />
                {focusEmail && !validEmail && (
                  <span className="access-credentials-instruction">
                    <IconInfoCircleFilled className="credentials-instruction-icon" />
                    <p className="">
                      - Must have one @ symbol.
                      <br />
                      - Part before @ can contain letters and numbers.
                      <br />- Part after @ must have letters and at least one period (.).
                    </p>
                  </span>
                )}
              </span>
              <span className="access-inputs">
                <IconKeyFilled className="access-icons" />
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  onFocus={() => {
                    setFocusPassword(true);
                    clearErrorMsg();
                  }}
                  onBlur={() => setFocusPassword(false)}
                  autoComplete="off"
                  className={`${focusPassword && !validPassword ? "invalid-inputs" : ""}`}
                  placeholder="password"
                />
                {focusPassword && !validPassword && (
                  <span className="access-credentials-instruction">
                    <IconInfoCircleFilled className="credentials-instruction-icon" />
                    <p className="">
                      - Must be between 8 and 20 characters long
                      <br />
                      - Must include atleast one of each: capital and small letters, numbers and special characters.
                      <br />- Allowed Special characters: !,@,#,$,%,^,&,*
                    </p>
                  </span>
                )}
              </span>
            </div>
            <div className="action-response">
              {errorMsg && <p>{errorMsg}</p>}
              <button onClick={initiateSignUp}>Sign Up</button>
            </div>

            <div className="login-signup-toggle">
              <p>Already invoicesXpress'ed?</p>
              <p
                onClick={() => {
                  setMode("Login");
                  clearInputsAndStates();
                }}
              >
                Login
              </p>
            </div>
            <div className="user-agreement">
              <p>
                By Signing up you provide your consent to our
                <span>Terms & Conditions</span> and <span>Privacy Policy</span>.
              </p>
              <p>You may opt to leave the site if you do not wish to provide consent for those.</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginSignup;
