import React, { useState, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import useAccessHooks from "../config/URLs/URLCallHooks/useAccessHooks";

const PersistProtectedRoutes = () => {
  const { user, setUser } = useAuth();
  const { refreshSession } = useAccessHooks();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      if (!user) {
        try {
          const newSession = await refreshSession();
          setUser(newSession);
          console.log("Your session has been refreshed...");
        } catch (error) {
          console.error("Session refresh failed", error);
          navigate("/", { replace: true });
          return;
        }
      }
      setLoading(false);
    };

    checkSession();
  }, [user, setUser, navigate, refreshSession]);

  return loading ? (
    <div>Loading...</div>
  ) : user ? (
    <Outlet />
  ) : (
    navigate("/", { replace: true })
  );
};

export default PersistProtectedRoutes;
