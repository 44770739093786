import React, { useState } from "react";
import "./kyc.css";

import { namedMonth } from "../../../../../utils/dateUtility";
import {
  provinces,
  getDistrictsByProvince,
  getMunicipalitiesByProvinceAndDistrict,
  getWardsByMunicipality,
} from "./AdministrativeDivision";
import FileUploader from "../../../../../components/FileUploader/FileUploader";
import { generateInvalidKeysMessageForObject } from "../../../../../utils/DataValidators/ObjectValidator";
import { generateMissingDocumentMessage } from "../../../../../utils/DataValidators/DocumentValidator";

import { useResponse } from "../../../../../context/ResponseContext";
import useMemberActions from "../../../../../config/URLs/URLCallHooks/useMemberActions";

const KYC = ({ open, handleClose }) => {
  const { setResponseMessage } = useResponse();
  const { saveMemberKYC, saveMemberKYCDocuments, deleteMemberKYCRecord } = useMemberActions();

  const today = new Date();
  const KYCRegistrationMinAgeLimit = new Date(new Date().setFullYear(new Date().getFullYear() - 16))
    .toISOString()
    .split("T")[0];

  const [memberIdentityDetail, setMemberIdentityDetail] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    gender: "",
    dateOfBirth: "",
    contact: "",
    citizenshipNo: "",
  });

  const handleMemberIdentityDetailInput = (event) => {
    const { name, value } = event.target;

    setMemberIdentityDetail((prevValues) => {
      if (name === "dateOfBirth") {
        const dateEntered = new Date(value);
        const currentDate = new Date(KYCRegistrationMinAgeLimit);

        if (dateEntered > currentDate) {
          setResponseMessage({
            status: "error",
            title: "Minimum Age Not Met",
            message: "You must be at least 16 years old.",
          });
          return {
            ...prevValues,
            dateOfBirth: "",
          };
        }
      }
      return {
        ...prevValues,
        [name]: value,
      };
    });
  };

  const [permanentAddress, setPermanentAddress] = useState({
    province: "",
    district: "",
    municipality: "",
    wardNo: "",
    tole: "",
  });

  const handlePermanentAddressInput = (event) => {
    const { name, value } = event.target;
    setPermanentAddress((prevState) => {
      const updatedAddress = { ...prevState, [name]: value };

      if (name === "province") {
        updatedAddress.district = updatedAddress.municipality = updatedAddress.wardNo = updatedAddress.tole = "";
      } else if (name === "district") {
        updatedAddress.municipality = updatedAddress.wardNo = updatedAddress.tole = "";
      } else if (name === "municipality") {
        updatedAddress.wardNo = updatedAddress.tole = "";
      }
      return updatedAddress;
    });
  };

  const [temporaryAddress, setTemporaryAddress] = useState({
    province: "",
    district: "",
    municipality: "",
    wardNo: "",
    tole: "",
  });

  const handleTemporaryAddressInput = (event) => {
    const { name, value } = event.target;
    setTemporaryAddress((prevState) => {
      const updatedAddress = { ...prevState, [name]: value };

      if (name === "province") {
        updatedAddress.district = updatedAddress.municipality = updatedAddress.wardNo = updatedAddress.tole = "";
      } else if (name === "district") {
        updatedAddress.municipality = updatedAddress.wardNo = updatedAddress.tole = "";
      } else if (name === "municipality") {
        updatedAddress.wardNo = updatedAddress.tole = "";
      }
      return updatedAddress;
    });
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckBoxChange = (event) => {
    const isChecked = event.target.checked;
    setIsChecked(isChecked);

    if (isChecked) {
      setTemporaryAddress({ ...permanentAddress });
    } else {
      setTemporaryAddress({
        province: "",
        district: "",
        municipality: "",
        wardNo: "",
        tole: "",
      });
    }
  };

  const [KYCFiles, setKYCFiles] = useState({
    memberImage: null,
    citizenshipFront: null,
    citizenshipBack: null,
  });

  const handleFileSelect = (type, file) => {
    setKYCFiles((prevState) => ({
      ...prevState,
      [type]: file,
    }));
  };

  const initiateKYCSubmission = async () => {
    memberIdentityDetail.middlename === "" && delete memberIdentityDetail.middlename;

    const invalidKeysMessageForMemberIdentityDetailObject = generateInvalidKeysMessageForObject(memberIdentityDetail);
    if (invalidKeysMessageForMemberIdentityDetailObject) {
      setResponseMessage({
        status: "error",
        title: "Missing Information In KYC Form",
        message: invalidKeysMessageForMemberIdentityDetailObject,
      });
      return;
    }

    const invalidKeysMessageForPermanentAddressObject = generateInvalidKeysMessageForObject(permanentAddress);
    if (invalidKeysMessageForPermanentAddressObject) {
      setResponseMessage({
        status: "error",
        title: "Missing Information In KYC Form",
        message: <>Permanent Address's {invalidKeysMessageForPermanentAddressObject}</>,
      });
      return;
    }

    const invalidKeysMessageForTemporaryAddressObject = generateInvalidKeysMessageForObject(temporaryAddress);
    if (invalidKeysMessageForTemporaryAddressObject) {
      setResponseMessage({
        status: "error",
        title: "Missing Information In KYC Form",
        message: <>Temporary Address's {invalidKeysMessageForTemporaryAddressObject}</>,
      });
      return;
    }

    const missingDocuments = [];
    if (!KYCFiles.memberImage) missingDocuments.push("member image");
    if (!KYCFiles.citizenshipFront) missingDocuments.push("citizenship front");
    if (!KYCFiles.citizenshipBack) missingDocuments.push("citizenship back");

    const missingDocumentsMessage = generateMissingDocumentMessage(missingDocuments);
    if (missingDocumentsMessage) {
      setResponseMessage({
        status: "error",
        title: "Missing Information In KYC Form",
        message: missingDocumentsMessage,
      });
      return;
    }

    const memberDocuments = new FormData();
    memberDocuments.append("memberImage", KYCFiles.memberImage);
    memberDocuments.append("citizenshipFront", KYCFiles.citizenshipFront);
    memberDocuments.append("citizenshipBack", KYCFiles.citizenshipBack);

    const memberKYCInfo = { memberIdentityDetail, permanentAddress, temporaryAddress };

    const response = await saveMemberKYC(memberKYCInfo);
    if (response) {
      const docSubmitted = await saveMemberKYCDocuments(memberDocuments);
      if (docSubmitted) handleClose();
    }
  };

  const initiateKYCDeletion = async () => {
    await deleteMemberKYCRecord();
    handleClose();
  };

  if (!open) return null;

  return (
    <div className="kyc-overlay" onClick={handleClose}>
      <div className="kyc-envelope" onClick={(e) => e.stopPropagation()}>
        {/* site-identity-and-title */}
        <div className="kyc-site-identity-envelope">
          <div className="kyc-logo-section">
            <div className="kyc-site-image-envelope">
              <img src="./logo192.png" alt="company-identity" />
            </div>
            <div className="kyc-sitename">
              <p>invoices</p>
              <p>Xpress</p>
            </div>
          </div>
          <h2>New KYC Form</h2>
          <div className="kyc-form-title-date">
            <h3 style={{ textAlign: "right" }}>Customer KYC</h3>
            <input type="text" value={namedMonth(today)} style={{ textAlign: "right" }} readOnly />
          </div>
        </div>
        {/* actual form section */}
        <div className="kyc-form-outer-envelope">
          <div className="kyc-form-envelope">
            <div className="kyc-member-image-and-instructions-envelope">
              <h3>KYC INSTRUCTIONS</h3>
              <div className="kyc-member-image-and-instructions-content">
                <div className="kyc-instruction-points">
                  <li>Member must be at least 16 years old at the time of KYC submission.</li>
                  <li>Provide a passport size identification image not older than 6 months.</li>
                  <li>Fill in your details as per your citizenship.</li>
                  <li>Upload colored copy of your citizenship's front and back separately.</li>
                  <li>Once submitted, no changes can be made until a decision is made for submitted KYC.</li>
                  <li>KYC verification make take upto two business days.</li>
                  <li>You can still continue to explore the application as you wait KYC verification.</li>
                  <li>!X does not ensure data preservation for unverified accounts.</li>
                </div>

                <div className="member-image-envelope">
                  <h4>Applicant Photo</h4>
                  <FileUploader onFileSelect={(file) => handleFileSelect("memberImage", file)} />
                </div>
              </div>
            </div>
            <hr />
            <div className="identity-detail">
              <h3>IDENTITY DETAILS</h3>
              <div className="identity-detail-inputs-row">
                <div className="kyc-input-envelope">
                  <input
                    type="text"
                    name="firstname"
                    value={memberIdentityDetail.firstname}
                    onChange={handleMemberIdentityDetailInput}
                  />
                  <label>Firstname</label>
                </div>
                <div className="kyc-input-envelope">
                  <input
                    type="text"
                    name="middlename"
                    value={memberIdentityDetail.middlename}
                    onChange={handleMemberIdentityDetailInput}
                  />
                  <label>Middlename</label>
                </div>
                <div className="kyc-input-envelope">
                  <input
                    type="text"
                    name="lastname"
                    value={memberIdentityDetail.lastname}
                    onChange={handleMemberIdentityDetailInput}
                  />
                  <label>Lastname</label>
                </div>
              </div>

              <div className="identity-detail-inputs-row">
                <div className="kyc-input-envelope">
                  <select name="gender" value={memberIdentityDetail.gender} onChange={handleMemberIdentityDetailInput}>
                    <option>Select gender</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Others</option>
                  </select>
                  <label>Select Gender</label>
                </div>
                <div className="kyc-input-envelope">
                  <input
                    type="date"
                    name="dateOfBirth"
                    value={memberIdentityDetail.dateOfBirth}
                    max={KYCRegistrationMinAgeLimit}
                    onChange={handleMemberIdentityDetailInput}
                  />
                  <label>Date Of Birth (A.D)</label>
                </div>
                <div className="kyc-input-envelope">
                  <input
                    type="text"
                    name="contact"
                    value={memberIdentityDetail.contact}
                    onChange={handleMemberIdentityDetailInput}
                  />
                  <label>Contact</label>
                </div>
              </div>
            </div>
            <hr />

            <div className="address-detail">
              <h3>ADDRESS DETAILS</h3>
              <div className="address-types">
                <div className="permanent-address">
                  <div className="address-type-title">
                    <h4>Permanent Address</h4>
                  </div>

                  <div className="address-detail-inputs-col">
                    <div className="kyc-input-envelope">
                      <select name="province" value={permanentAddress.province} onChange={handlePermanentAddressInput}>
                        <option className="address-select-options">Select a province</option>
                        {provinces.map((province, index) => (
                          <option key={index} value={province} className="address-select-options">
                            {province}
                          </option>
                        ))}
                      </select>
                      <label>Province</label>
                    </div>
                    <div className="kyc-input-envelope">
                      <select name="district" value={permanentAddress.district} onChange={handlePermanentAddressInput}>
                        <option className="address-select-options">Select a district</option>
                        {permanentAddress.province &&
                          getDistrictsByProvince(permanentAddress.province).map((district, index) => (
                            <option key={index} value={district} className="address-select-options">
                              {district}
                            </option>
                          ))}
                      </select>
                      <label>District</label>
                    </div>
                    <div className="kyc-input-envelope">
                      <select
                        name="municipality"
                        value={permanentAddress.municipality}
                        onChange={handlePermanentAddressInput}
                      >
                        <option className="address-select-options">Select a municipality</option>
                        {permanentAddress.province &&
                          permanentAddress.district &&
                          getMunicipalitiesByProvinceAndDistrict(
                            permanentAddress.province,
                            permanentAddress.district
                          ).map((municipality, index) => (
                            <option key={index} value={municipality} className="address-select-options">
                              {municipality}
                            </option>
                          ))}
                      </select>
                      <label>Municipality</label>
                    </div>
                    <div className="ward-and-tole-envelope">
                      <span className="ward-selector">
                        <select name="wardNo" value={permanentAddress.wardNo} onChange={handlePermanentAddressInput}>
                          <option className="address-select-options">Ward No</option>
                          {permanentAddress.province &&
                            permanentAddress.district &&
                            permanentAddress.municipality &&
                            getWardsByMunicipality(
                              permanentAddress.province,
                              permanentAddress.district,
                              permanentAddress.municipality
                            ).map((ward, index) => (
                              <option key={index} value={ward} className="address-select-options">
                                {ward}
                              </option>
                            ))}
                        </select>
                        <label>Ward</label>
                      </span>
                      <span className="tole-selector">
                        <input
                          type="text"
                          name="tole"
                          value={permanentAddress.tole}
                          onChange={handlePermanentAddressInput}
                        />
                        <label>Tole</label>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="temporary-address">
                  <div className="address-type-title">
                    <h4>Temporary Address</h4>
                    <div className="temp-perm-check">
                      <input type="checkbox" checked={isChecked} onChange={handleCheckBoxChange} />
                      <p>As 'Permanent Address'</p>
                    </div>
                  </div>

                  <div className="address-detail-inputs-col">
                    <div className="kyc-input-envelope">
                      <select name="province" value={temporaryAddress.province} onChange={handleTemporaryAddressInput}>
                        <option className="address-select-options">Select a province</option>
                        {provinces.map((province, index) => (
                          <option key={index} value={province} className="address-select-options">
                            {province}
                          </option>
                        ))}
                      </select>
                      <label>Province</label>
                    </div>
                    <div className="kyc-input-envelope">
                      <select name="district" value={temporaryAddress.district} onChange={handleTemporaryAddressInput}>
                        <option className="address-select-options">Select a district</option>
                        {temporaryAddress.province &&
                          getDistrictsByProvince(temporaryAddress.province).map((district, index) => (
                            <option key={index} value={district} className="address-select-options">
                              {district}
                            </option>
                          ))}
                      </select>
                      <label>District</label>
                    </div>
                    <div className="kyc-input-envelope">
                      <select
                        name="municipality"
                        value={temporaryAddress.municipality}
                        onChange={handleTemporaryAddressInput}
                      >
                        <option className="address-select-options">Select a municipality</option>
                        {temporaryAddress.province &&
                          temporaryAddress.district &&
                          getMunicipalitiesByProvinceAndDistrict(
                            temporaryAddress.province,
                            temporaryAddress.district
                          ).map((municipality, index) => (
                            <option key={index} value={municipality} className="address-select-options">
                              {municipality}
                            </option>
                          ))}
                      </select>
                      <label>Municipality</label>
                    </div>
                    <div className="ward-and-tole-envelope">
                      <span className="ward-selector">
                        <select name="wardNo" value={temporaryAddress.wardNo} onChange={handleTemporaryAddressInput}>
                          <option className="address-select-options">Ward No</option>
                          {temporaryAddress.province &&
                            temporaryAddress.district &&
                            temporaryAddress.municipality &&
                            getWardsByMunicipality(
                              temporaryAddress.province,
                              temporaryAddress.district,
                              temporaryAddress.municipality
                            ).map((ward, index) => (
                              <option key={index} value={ward} className="address-select-options">
                                {ward}
                              </option>
                            ))}
                        </select>
                        <label>Ward</label>
                      </span>
                      <span className="tole-selector">
                        <input
                          type="text"
                          name="tole"
                          value={temporaryAddress.tole}
                          onChange={handleTemporaryAddressInput}
                        />
                        <label>Tole</label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className="member-fies-upload-section">
              <h3>CITIZENSHIP DETAIL</h3>
              <div className="member-citizenship-images">
                <div className="citizenship-front-image-envelope">
                  <h4>Citizenship Front</h4>
                  <FileUploader onFileSelect={(file) => handleFileSelect("citizenshipFront", file)} />
                </div>
                <div className="citizenship-back-image-envelope">
                  <h4>Citizenship Back</h4>
                  <FileUploader onFileSelect={(file) => handleFileSelect("citizenshipBack", file)} />
                </div>
              </div>
              <div className="member-citizenship-details">
                <input
                  type="text"
                  name="citizenshipNo"
                  value={memberIdentityDetail.citizenshipNo}
                  onChange={handleMemberIdentityDetailInput}
                />
                <label>Citizenship No</label>
              </div>
            </div>
            <div className="legal-notice">
              <h3>IMPORTANT</h3>
              <div className="user-agreements">
                <p>~By submitting the KYC, member agrees to comply with the Terms and Policies of !X.</p>
                <p>
                  ~No member can request to delete their data immediately at any point, including their business data if
                  they have registered one.
                </p>
                <p>
                  ~Data will only be deleted if deemed as legal by !X, or else they will continue to exist for any
                  future disputes.
                </p>
              </div>
            </div>
            {/* submit button */}
            <button onClick={initiateKYCSubmission}>Submit KYC</button>
            <button onClick={initiateKYCDeletion}>Delete KYC</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KYC;
