import React, { useState } from "react";
import "./OTPVerifier.css";

import { useNavigate } from "react-router-dom";

import { generateInvalidKeysMessageForObject } from "../../../../../../utils/DataValidators/ObjectValidator";

import { useResponse } from "../../../../../../context/ResponseContext";
import useOTPActions from "../../../../../../config/URLs/URLCallHooks/useOTPActions";

const OTPVerifier = () => {
  const { verifyRegistrationOTP } = useOTPActions();
  const { setResponseMessage } = useResponse();

  const navigate = useNavigate();

  const [OTPDetails, setOTPDetails] = useState({
    email: "",
    OTP: "",
  });

  const handleOTPDetailsInput = (event) => {
    const { name, value } = event.target;

    setOTPDetails((prevValue) => ({ ...prevValue, [name]: value }));
  };

  const initiateOTPVerification = async () => {
    const invalidKeysMessageForOTPDetailsObject = generateInvalidKeysMessageForObject(OTPDetails);
    if (invalidKeysMessageForOTPDetailsObject) {
      setResponseMessage({
        status: "error",
        title: "Missing Values",
        message: invalidKeysMessageForOTPDetailsObject,
      });
      return;
    }

    const response = await verifyRegistrationOTP(OTPDetails);
    if (response) navigate("/");
    else return;
  };

  return (
    <div className="otp-overlay">
      <div className="otp-envelope">
        <div className="otp-site-identity">
          <div className="otp-site-image">
            <img src="./logo192.png" alt="site-identity-logo" />
          </div>
          <div className="otp-site-name">
            <h2>invoicesXpress</h2>
          </div>
        </div>
        <div className="otp-verifier-heading">
          <h2>OTP Verification</h2>
          <p>Hmm... We need to verify you.</p>
        </div>
        <p className="otp-sarcasm">
          So, yes, we’re asking for your email again. Why? <br />
          <br />
          Because we don’t have an automated system for this. Or maybe we’re just lazy to try to implement it. Either
          way, we need it to match the OTP you’ll send us. So, stop being lazy and type it out.
        </p>

        <div className="otp-input-envelope">
          <input
            type="text"
            name="email"
            value={OTPDetails.email}
            onChange={handleOTPDetailsInput}
            autoComplete="off"
          />
          <label>e-mail</label>
        </div>
        <div className="otp-input-envelope">
          <input type="text" name="OTP" value={OTPDetails.OTP} onChange={handleOTPDetailsInput} autoComplete="off" />
          <label>OTP</label>
        </div>

        <button onClick={initiateOTPVerification}>Submit</button>
      </div>
    </div>
  );
};

export default OTPVerifier;
