import Cookies from "js-cookie";

import useAPICall from "../../APICallHooks/useAPICall";
import usePrivateAPICall from "../../APICallHooks/usePrivateAPICall";
import { useAuth } from "../../../context/AuthContext";
import { useResponse } from "../../../context/ResponseContext";

import { SIGNUP_URL, LOGIN_URL, LOGOUT_URL, REFRESH_SESSION_URL } from "../URLsDefinations/AccessURLs";

const useAccessHooks = () => {
  const { executeAPICall } = useAPICall();
  const { executePrivateAPICall } = usePrivateAPICall();
  const { setUser } = useAuth();
  const { setResponseMessage } = useResponse();

  const signup = async (signupCredentials) => {
    const controller = new AbortController();
    const { signal } = controller;

    try {
      const response = await executeAPICall({
        URL: SIGNUP_URL,
        method: "post",
        parameters: { signupCredentials },
        signal,
      });

      if (response?.success) {
        setResponseMessage({
          status: "success",
          title: "Registration Successful",
          message: response?.data?.message,
        });
      }

      return response;
    } catch (error) {
      if (error.name !== "AbortError")
        setResponseMessage({
          status: "error",
          title: "Signup Request Failed",
          message: error.message,
        });
      return;
    } finally {
      controller.abort();
    }
  };

  const login = async (loginCredentials) => {
    const controller = new AbortController();
    const { signal } = controller;

    try {
      const response = await executeAPICall({
        URL: LOGIN_URL,
        method: "post",
        parameters: { loginCredentials },
        signal,
      });

      if (response?.success) setUser(response?.data);
      if (response?.status === 412) {
        setResponseMessage({
          status: "error",
          title: "E-mail Verification Incomplete",
          message: response?.errorMessage,
        });
      }
      if (response?.status === 428) {
        setResponseMessage({
          status: "error",
          title: "E-mail Verification Pending",
          message: response?.errorMessage,
        });
      }
      return response;
    } catch (error) {
      if (error.name !== "AbortError") {
        setResponseMessage({
          status: "error",
          title: "Login Request Failed",
          message: error.message,
        });
        return;
      }
    } finally {
      controller.abort();
    }
  };

  const refreshSession = async () => {
    const controller = new AbortController();
    const { signal } = controller;

    try {
      const response = await executePrivateAPICall({
        URL: REFRESH_SESSION_URL,
        method: "post",
        signal,
      });

      if (response?.success) {
        return response?.data;
      } else {
        setResponseMessage({
          status: "error",
          title: "Session Re-initialization Failed",
          message: response?.errorMessage,
        });
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        setResponseMessage({
          status: "error",
          title: "Session Re-initialization Request Failed",
          message: error.message,
        });
        return;
      }
    } finally {
      controller.abort();
    }
  };

  const logout = async () => {
    const controller = new AbortController();
    const { signal } = controller;

    try {
      console.log("TRYING LOGOUT...");
      const response = await executeAPICall({
        URL: LOGOUT_URL,
        method: "post",
        signal,
      });

      console.log("RESPONSE", response);

      console.log("Logout Response", response);
      if (response?.success) {
        setResponseMessage({
          status: "success",
          title: "Logout Complete",
          message: response?.data?.message,
        });
        Cookies.remove("accessToken", {
          path: "",
          domain: "dev.invoicesxpress.com",
        });
        Cookies.remove("refreshToken", {
          path: "",
          domain: "dev.invoicesxpress.com",
        });

        return true;
      } else {
        setResponseMessage({
          status: "error",
          title: "Logout Failed",
          message: response?.errorMessage,
        });
      }
      return;
    } catch (error) {
      if (error.name !== "AbortError") {
        setResponseMessage({
          status: "error",
          title: "Logout Request Failed",
          message: error.message,
        });
      }
      return;
    } finally {
      controller.abort();
    }
  };

  return { signup, login, refreshSession, logout };
};

export default useAccessHooks;
