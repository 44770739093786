import axios from "axios";
import useAccessHooks from "../URLs/URLCallHooks/useAccessHooks";

const BASE_URL =
  process.env.NODE_ENV === "development" ? "https://dev.invoicesxpress.com/api/" : "https://invoicesxpress.com/api/";

// Create a normal axios instance for public API calls
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Create a protected axios instance for authenticated API calls
const axiosProtectedInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

axiosProtectedInstance.interceptors.request.use((config) => {
  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  } else {
    config.headers["Content-Type"] = "application/json";
  }
  return config;
});

axiosProtectedInstance.interceptors.response.use(
  (response) => response, // Pass through successful responses
  async (error) => {
    if (error.response?.status === 401) {
      try {
        const { refreshSession } = useAccessHooks();
        // Trigger session refresh logic
        const refreshResponse = await refreshSession();
        console.log("REQUESTING REFRESH");

        if (refreshResponse?.success) {
          console.log("FROM AXIOS INSTANCE RETRY", error.config);
          // Retry the original request with the new token
          return axiosProtectedInstance(error.config); // This will retry the failed request
        }
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        // Handle logout or redirect to login page if refresh fails
        return Promise.reject(refreshError);
      }
    }

    // Handle other types of errors (non-401)
    return Promise.reject(error);
  }
);

export { axiosInstance, axiosProtectedInstance };
