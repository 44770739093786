import React, { useState } from "react";
import "./homeNavbar.css";

import LoginSignup from "./Subcomponents/LoginSignupDialog/LoginSignup";

const HomeNavbar = () => {
  const [openLoginSignupDialog, setOpenLoginSignupDialog] = useState(false);
  const [mode, setMode] = useState(null);

  return (
    <>
      <div className="home-navbar-envelope">
        <div className="home-navbar-logo-envelope">
          <img src="/logo192.png" alt="logo" />
        </div>
        <div className="home-navbar-links-actions">
          <div className="home-navbar-links">
            <h3>ABOUT</h3>
            <h3>Privacy Policy</h3>
            <h3>Terms & Conditions</h3>
          </div>

          <div className="home-actions">
            <button
              className="login-btn"
              onClick={() => {
                setMode("Login");
                setOpenLoginSignupDialog(true);
              }}
            >
              Login
            </button>

            <button
              className="signup-btn"
              onClick={() => {
                setMode("Sign Up");
                setOpenLoginSignupDialog(true);
              }}
            >
              Signup
            </button>
          </div>
        </div>
      </div>
      <LoginSignup
        open={openLoginSignupDialog}
        handleClose={() => setOpenLoginSignupDialog(false)}
        form={{ mode, setMode }}
      />
    </>
  );
};

export default HomeNavbar;
